<template>
  <div class="row justify-content-center py-2 py-lg-5" data-aos="fade-up" data-aos-duration="1500">
    <div class="col-12 col-lg-10 py-2 py-lg-5">
      <div class="row align-items-center">
        <div class="col-12 col-lg-4 pb-4">
          <h2
            class="text-uppercase tx__w-bold text-center text-lg-left pb-4"
            v-html="title"
          ></h2>
          <div class="w-100 py-3" v-html="copy"></div>
          <router-link :to="{ path: getSlug(link.url) }" class="bt bt__primary d-block mx-auto mx-lg-0">
            {{ link.title }}
            <div class="bt__primary--horizontal"></div>
            <div class="bt__primary--vertical"></div>
          </router-link>
        </div>
        <div class="col-12 col-lg-8 py-4">
          <div class="row align-items-center slider-referees">
            
            <div class="col-12 slider-col">
              <splide ref="referees" :options="options" v-if="refdata.length">
                <splide-slide v-for="(i, index) in refdata" :key="index">
                  <div class="d-flex flex-column justify-content-center item-slider">
                    <figure class="figure figure__square">
                      <img
                       v-if="i.fimg_url.full"
                        :src="i.fimg_url.full"
                        alt="Referee Hourgian"
                        class="img-fluid d-block mx-auto"
                      />
                      <img
                    v-else
                    :src="dummyimg"
                    :alt="i.title.rendered"
                    class="img"
                  />
                    </figure>
                     <h3 class="text-center name">{{ i.title.rendered }}</h3>
                  <span class="line"></span>
                  <div
                    class="text-center pt-2 p-m0 job-title tx__size-rg"
                    v-html="i.acf.position"
                  ></div>
                  </div>
                </splide-slide>
              </splide>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide-core.min.css";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
export default {
  props: {
    refdata: Array,
    title: String,
    copy: String,
    link: Object,
    dummyimg: String,
  },
  data() {
    return {
      options: {
        perPage: 3,
        arrows: true,
        pagination: false,
        type: "loop",
        focus: "center",
        gap: "0rem",
        easing: "ease",
        speed: 600,
        padding: {
          right: '1rem',
          left : '1rem',
        },
         breakpoints: {
		      576: {
            perPage: 1,
            gap: "1rem"
          },
          768: {
            perPage: 1,
            gap: "1rem"
          },
        } 
      },
    };
  },
  components: {
    Splide,
    SplideSlide,
  },
  methods: {
    getSlug(theurl) {
      let theslug = theurl.substring(
        theurl.lastIndexOf("/", theurl.lastIndexOf("/") - 1)
      );
      return theslug;
    },
    goNext() {
      this.$refs.referees.go("+");
    },
    goPrev() {
      this.$refs.referees.go("-");
    },
  },
};
</script>

<style>
</style>