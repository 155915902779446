import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Page from '../views/Page.vue'
import Post from '../views/Post.vue'
import Category from '../views/Category.vue'
import VueMeta from 'vue-meta';


Vue.use(VueMeta, {
  keyName: 'head',
  
})
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/page/:slug',
    name: 'page',
    component: Page
  },
  {
    path: '/post/:id',
    name: 'post',
    component: Post
  },
  {
    path: '/category/:id',
    name: 'category',
    component: Category
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/our-reputation',
    name: 'Reputation',
    component: () => import(/* webpackChunkName: "reputation" */ '../views/Reputation.vue')
  },
  {
    path: '/our-people',
    name: 'People',
    component: () => import(/* webpackChunkName: "people" */ '../views/People.vue')
  }
  ,
  {
    path: '/our-insights',
    name: 'Insights',
    component: () => import(/* webpackChunkName: "insights" */ '../views/Insights.vue')
  }
  ,
  {
    path: '/our-community',
    name: 'Community',
    component: () => import(/* webpackChunkName: "community" */ '../views/Community.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  base: process.env.BASE_URL,
  routes
})

export default router
