<template>
  <div class="w-100 loading-container d-flex align-items-center justify-content-center">
        <img src="../assets/img/loading.svg" alt="Loading" class="img-loading d-block" />
      </div>
</template>

<script>
export default {

}
</script>
