<template>
  <div>
    <div class="w-100 py-5 home-cover d-none d-md-block">
      <kinesis-container>
        <kinesis-element :strength="0" :axis="'y'">
          <img :src="imageDesktop" class="referees" alt="Hourigan Referees" />
        </kinesis-element>
      </kinesis-container>
      <img
        src="@/assets/img/creative-leadership.svg"
        alt="Creative leadership"
        id="mask"
      />
    </div>
    <div class="container-fluid d-md-none">
      <div class="row py-4">
        <div class="col-12 position-relative">
          <img
        src="@/assets/img/creative_mob.svg"
        alt="Creative leadership"
        id="mask_mob"
      />
          <splide ref="charities" :options="options" v-if="imageMobile.length">
            <splide-slide v-for="(i, index) in imageMobile" :key="index">
              <div class="w-100 h-100 d-flex align-items-center">
                <img :src="i.image" alt="Creative leadership" />
              </div>
            </splide-slide>
          </splide>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { KinesisContainer, KinesisElement } from "vue-kinesis";
export default {
  components: {
    KinesisContainer,
    KinesisElement,
    Splide,
    SplideSlide,
  },
  props: {
    imageDesktop: String,
    imageMobile: Array,
  },
  data() {
    return {
      options: {
        perPage: 1,
        arrows: false,
        pagination: false,
        type: "loop",
        gap: "1rem",
        easing: "ease",
        autoplay: true,
      },
    };
  },
};
</script>

<style>
</style>