<template>
  <div class="container-fluid">
    <div class="row py-5">
      <div class="col-12">
        <div class="row justify-content-end align-items-center overflow-xh">
          <div class="col-12 col-lg-5 col-xl-4 bg__secondary py-3 py-xl-5 z-index-2 mn-r-8 order-2 order-lg-1 " data-aos="fade-right"  data-aos-duration="1500">
            <div class="w-100 px-3 px-xl-5 py-4">
              <h2 class="text-uppercase tx__w-bold mx-auto pb-4 text-center text-lg-left" v-html="title">
              </h2>
              <div class="w-100 py-3" v-html="copy"></div>
              <router-link :to="{ path: getSlug(link.url) }" class="bt bt__primary d-block mx-auto mx-lg-0">
                 {{link.title}}
                <div class="bt__primary--horizontal"></div>
                <div class="bt__primary--vertical"></div>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-7 col-xl-8 order-1 order-lg-2 pt-4">
            <figure class="figure figure__rect img--grayscale">
              <img :src="image" alt="Hourigan" />
              <span class="stroke stroke-top-left"></span>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    copy: String,
    link: Object,
  },
  methods: {
    getSlug(theurl){
      let theslug = theurl.substring(theurl.lastIndexOf('/', theurl.lastIndexOf('/')-1))
      return theslug;
    }
  },
};
</script>

<style>
</style>