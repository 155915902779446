<template>
  <div class="col-12 pb-4 post-item" v-bind:class="cols">
      <router-link :to="{ name: 'post', params: { id: id }}">
        <figure class="figure figure__rect">
        <img v-if="img" :src="img" :alt="title" class="img img--grayscale"/>
        <img v-else :src="dummyimg" :alt="title" class="img"/>
        </figure>
      </router-link>
    <div class="w-100 d-flex align-items-center">
      <time class="text-uppercase">{{ datePostFormat(date) }}</time>
      <span class="px-2 py-4">|</span>
      <small v-if="cat" class="font__primary text-uppercase tx__space-2">{{cat}}</small>
    </div>
    <router-link :to="{ name: 'post', params: { id: id }}" class="h4 font__primary mb-2 d-block" v-html="title"></router-link>
    <div class="w-100" v-html="excerpt"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    img: String,
    excerpt: String,
    date: String,
    id: Number,
    cols: String,
    dummyimg: String,
    cat: String
  },
  methods: {
    datePostFormat(d) {
      let theDate = new Date(d);
      let year = theDate.getFullYear(); // 2019
      const months = {
        0: "jan",
        1: "feb",
        2: "mar",
        3: "apr",
        4: "may",
        5: "jun",
        6: "jul",
        7: "aug",
        8: "sep",
        9: "oct",
        10: "nov",
        11: "dec",
      };
      let monthName = months[theDate.getMonth()]
      let day = theDate.getDate(); // 23

      return monthName + " "+ day +", " + year;
    }
  }
};
</script>

<style>
</style>