<template>
    <div class="nav-overlay">
        <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
        <transition name="slide">
            <div v-if="isPanelOpen" class="sidebar-panel px-3 d-flex align-items-center">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script>
    export default {
        methods: {
            closeSidebarPanel(){
                this.$store.dispatch('toggleNav');
            }
        },
        computed: {
            isPanelOpen() {
                return this.$store.getters["isNavOpen"];
            }
        }
    }
</script>
<style>
    .slide-enter-active,
    .slide-leave-active
    {
        transition: transform 0.8s ease;
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(-100%);
        transition: all 0.8s ease-in 0s
    }
</style>