<template>
  <div class="page-template-default page" :class="classes">
    <Header :data="getterOptions" />
    <main class="site-main">
      <slot v-bind:options="getterOptions" v-bind:categories="getterCategories"></slot>
    </main>
    <Footer :data="getterOptions" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  props:{
    classes: Array,
    
  },
  data(){
    return{
      options: Object
    }
  },
  components:{
    Header, Footer
  },
  created() {
    this.$store.dispatch('options/getOptions');
    this.$store.dispatch('categories/getCategories');
  },
  computed:{
    getterOptions() {
      return this.$store.getters["options/allOptions"];
    },
    getterCategories() {
      return this.$store.getters["categories/allCategories"];
    },
  }
}
</script>