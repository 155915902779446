<template>
  <layout-page v-if="itLoads" :classes="pageClasses" v-slot:default="slotProps">
    <head-post v-if="slotProps.categories.length" :title="page.title.rendered" :date="page.date" :category="renderCatName(Object.values(slotProps.categories) ,page.categories[0])"></head-post>
    <div class="container-fluid py-5">
      <div class="row position-relative">
        <div class="col-12 col-lg-5 offset-lg-1 content-col pb-4 pb-lg-0">
          <figure class="figure figure__rect d-lg-none">
            <img v-if="page.fimg_url.full" :src="page.fimg_url.full" :alt="page.title.rendered" class="img--grayscale"/>
             <img v-else :src="slotProps.options.dummy_image" :alt="page.title.rendered" class="img"/>
          </figure>
          <div class="w-100" v-html="page.content.rendered"></div>
          <div class="social-share d-flex justify-content-center py-2">
            <ShareNetwork
              class="mx-2"
              network="facebook"
              :url="route"
              title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            >
              <i class="fab fah fa-lg fa-facebook"></i>
            </ShareNetwork>
            <ShareNetwork
              class="mx-2"
              network="twitter"
              :url="route"
              title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            >
              <i class="fab fah fa-lg fa-twitter"></i>
            </ShareNetwork>
            <ShareNetwork
              class="mx-2"
              network="linkedin"
              :url="route"
              title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            >
              <i class="fab fah fa-lg fa-linkedin"></i>
            </ShareNetwork>
          </div>
          <router-link :to="{ path: '/our-insights'}" class="link font-weight-bold text-center d-block text-uppercase">More insights</router-link>
        </div>
        <div class="col-12 offset-lg-1 col-lg-5 sidebar-col">
          <figure class="figure figure__rect d-none d-lg-block">
            <img v-if="page.fimg_url.full" :src="page.fimg_url.full" :alt="page.title.rendered" class="img--grayscale"/>
             <img v-else :src="slotProps.options.dummy_image" :alt="page.title.rendered" class="img"/>
          </figure>
          <related-content :dummyImage="slotProps.options.dummy_image" :key="componentKey"></related-content>

        </div>
      </div>
    </div>
  </layout-page>
</template>

<script>
import LayoutPage from "@/layouts/LayoutPage.vue";
import HeadPost from "@/components/template-parts/HeadPost.vue";
import RelatedContent from '@/components/template-parts/RelatedContent.vue';
import { VueSocialSharing, ShareNetwork } from "vue-social-sharing";


export default {
  components: {
    LayoutPage,
    HeadPost,
    VueSocialSharing,
    ShareNetwork,
    RelatedContent,
  },
  data() {
    return {
      id: this.$route.params.id,
      itLoads: false,
      dataLoading: true,
      dataErrored: false,
      page: Object,
      relatedPosts: Object,
      pageClasses: ["internal-page", "insight-page"],
      route: "https://hourigan-international.com" + this.$route.fullPath,
      componentKey: 0
    };
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchingData",
  },
  head() {
    return {
      title: this.dataLoading ? 'Loading...' : this.page.title.rendered,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.dataLoading ? 'Loading...' : this.page._yoast_wpseo_metadesc,
        },
      ],
    };
  },
  methods: {
    fetchingData() {
      this.fetchID = this.$route.params.id;
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp/v2/posts/" + this.fetchID)
        .then((response) => {
          this.page = response.data;
          this.itLoads = true;
        })
        .catch((error) => {
          this.dataErrored = true
          }).finally(() => this.dataLoading = false);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    renderCatName(categories, theId){
      if(typeof categories != 'undefined' || categories != null){
        let found = categories.find(element => element.id === theId);
        return found.name;
      }else{
        return "iInsight"
      }
        
      },
  },
  created() {
    this.fetchingData();
    this.forceRerender();
    
  },
};
</script>

<style>
</style>