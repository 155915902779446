<template>
  <div class="container-fluid py-1 py-lg-5" data-aos="fade-up" data-aos-duration="1500">
    <div class="row justify-content-center py-2 py-lg-5">
      <div class="col-12 col-lg-10">
        <div class="row insights-featured h-100">
            <div class="col-12 py-5">
                <div class="row align-items-center p-relative">
                    <div class="col-12 col-lg-4">
                        <h2 class="text-uppercase tx__w-bold mx-auto text-center text-lg-left" v-html="title"></h2>
                    </div>
                    <div class="d-none d-lg-block col-lg-8 background-insights hgh-60 img--grayscale" :style="{ backgroundImage: 'url(' + imageTwo + ')'}">
                    </div>
                </div>
                
                
            </div>
          <div class="col-12 col-lg-9" >
            <div class="w-100 p-4 hgh-60 d-flex bg__img" :style="{ backgroundImage: 'url(' + image + ')',}">
                <div class="row py-5 align-items-center justify-content-between">
                    <div class="col-12 col-md-5 py-4 py-lg-0 pl-xl-5" v-if="itLoadsPost" >
                        <h4 class="tx__shadow-sm">{{posts[0].title.rendered}}</h4>
                        <div class="w-100 tx__shadow-sm" v-html="posts[0].excerpt.rendered"></div>
                        <router-link :to="{ path: '/post/'+posts[0].id }" class="link link--readmore link--readmore-white tx__shadow-sm">Read more</router-link>
                    </div>
                    <div class="col-12 col-md-5 py-4 py-lg-0">
                        <ul class="m-0 list-unstyled insights-latest " v-if="typeof posts != 'undefined'">
                                        <li class="mb-4 " v-for="(i, index) in Object.values(posts).slice(1)" :key="index">
                                            <span class="font__primary text-uppercase tx__space-2 tx__shadow-sm">{{ renderCatName(categories, i.categories[0]) }}</span>
                                            <router-link :to="{ path: '/post/'+i.id }" class="link--white d-block tx__shadow-sm" v-html="i.title.rendered"></router-link>
                                        </li>
                                    </ul>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-12 col-lg-3  d-lg-flex flex-column">
            
              <div class="w-100 mt-auto" v-if="typeof link != 'undefined'">
                  <router-link v-if="link" :to="{ path: getSlug(link.url) }" class="bt bt__primary mx-auto">
                 {{link.title}}
                  <div class="bt__primary--horizontal"></div>
                  <div class="bt__primary--vertical"></div>
                </router-link>
              </div>
              
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        image: String,
        imageTwo: String,
        title: String,
        link: Object,
  },
  data(){
      return {
            posts: Object,
            itLoadsPost: false,
            categories:Object,
            itLoadsCat: false,
            firstPost: Array,
            postList: Array,
      }
  },
  methods: {
    getSlug(theurl){
      let theslug = theurl.substring(theurl.lastIndexOf('/', theurl.lastIndexOf('/')-1))
      return theslug;
    },
    fetchPost() {
        this.$axios
          .get( this.$axios.defaults.baseURL +'wp/v2/posts?per_page=5&tags=10')
          .then((response) => {
            this.posts = response.data;
            this.itLoadsPost = true;
          })
          .catch((error) => {
            console.log(error)
          })
      },
      fetchCategories() {
        this.$axios
          .get( this.$axios.defaults.baseURL +'wp/v2/categories?hide_empty=true')
          .then((response) => {
            this.categories = response.data;
            this.itLoadsCat = true;
          })
          .catch((error) => {
            console.log(error)
          })
      },
    renderCatName(categories, theId){
        let found = categories.find(element => element.id === theId);
        return found.name;
      },
  },
  mounted(){
      
  },
  created(){
      this.fetchPost();
      this.fetchCategories();
  }
};
</script>

<style>
</style>