<template>
  <div class="container-fluid">
      <div class="row py-5 position-relative">
        <div class="col-12">
          <div class="row align-items-center position-relative overflow-xh">
            <div class="col-12 col-lg-7 col-xl-8 py-4">
              <figure class="figure figure__rect img--grayscale">
                <img :src="image" alt="Hourigan People" />
                <span class="stroke stroke-top-right"></span>
              </figure>
            </div>
            <div class="col-12 col-lg-5 col-xl-4 bg__secondary mn-l-8 py-4 py-xl-5 z-index-2" data-aos="fade-left"  data-aos-duration="1500">
              <div class="w-100 px-lg-3 px-xl-5 py-3 py-xl-4">
                <h2 class="text-uppercase tx__w-bold mx-auto pb-4 text-center text-lg-left"  v-html="title"></h2>
                <div class="w-100 py-3" v-html="copy">
                </div>
                <router-link :to="{ path: getSlug(link.url) }" class="bt bt__primary d-block mx-auto mx-lg-0">
                  {{link.title}}
                  <div class="bt__primary--horizontal"></div>
                  <div class="bt__primary--vertical"></div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
props:{
    image: String,
    title: String,
    copy: String,
    link: Object,
  },
  methods: {
    getSlug(theurl){
      let theslug = theurl.substring(theurl.lastIndexOf('/', theurl.lastIndexOf('/')-1))
      return theslug;
    }
  },
}
</script>

<style>

</style>