<template>
<header class="site-header sticky-top">
  <div class="container-fluid top-bar">
    <div class="row py-3 align-items-center">
      <div class="col-3">
        <Burger/>
      </div>
      <div class="col-6 brand-title">
        <router-link to="/" @click.native="$store.dispatch('closeNav')"><h1 class="tx__c-primary text-uppercase text-center">Hourigan International</h1></router-link>
      </div>
      <div class="col-3 brand-logo d-flex">
        <router-link to="/" class="ml-auto px-2 d-block" @click.native="$store.dispatch('closeNav')">
                <img :src="data.logo_header" alt="Hourigan" class="logo">
              </router-link>
      </div>
    </div>
  </div>
  <Overlay>
    <div class="container-fluid pt-5">
      <div class="row justify-content-center tx__c-secondary">
        <div class="col-12 col-lg-10">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-5 d-none d-md-block">
              <ul class="list-unstyled">
                <li class="pb-5" v-if="data.phone">
                  <p class="font-weight-bold">Phone</p>
                  <a :href="'tel:'+data.phone" class="d-block link--gray">{{data.phone}}</a>
                </li>
                <li class="pb-5" v-if="data.email">
                  <p class="font-weight-bold">Email</p>
                  <a :href="'mailto:'+data.email" class="d-block link--gray" target="_blank">{{data.email}}</a>
                </li>
                <li class="pb-5" v-if="data.address">
                  <p class="font-weight-bold">Address</p>
                  <div class="w-100" v-html="data.address"></div>
                </li>
                <li class="pb-5">
                  <p class="font-weight-bold">Social Media</p>
                  <a :href="data.linkedin" v-if="data.linkedin" target="_blank" class="d-block link--gray">Linkedin</a>
                  <a :href="data.twitter" v-if="data.twitter" target="_blank" class="d-block link--gray">Twitter</a>
                  <a :href="data.facebook" v-if="data.facebook" target="_blank" class="d-block link--gray">Facebook</a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-6 col-lg-6 main-navigation">
              <ul v-if="itLoads" class="mt-5 mt-md-0 list-unstyled">
                  <li v-for="(item, index) in items" :key="item.ID" class="mb-1 mb-lg-4">
                    <span>0{{index+1}}</span>
                    <hr class="m-0">
                    <router-link :to="{ path: '/'+item.object_slug }" class="font__primary" @click.native="$store.dispatch('closeNav')"><span class="vertical-line"></span>{{ item.title }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Overlay>
</header>
  
</template>

<script>
import Burger from '@/components/navigation/Burger.vue';
import Overlay from '@/components/navigation/Overlay.vue';

export default {
  data() {
    return {
        locations: Object,
        menuID: String,
        items: Object,
        itLoads: false
    };
  },
  props:{
    data: Object
  },
  components:{
    Burger, Overlay
  },
  methods: {
    fetchingMenuLocations() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp-api-menus/v2/menu-locations/")
        .then(response => {
          this.locations = response.data;
          this.menuID = this.locations.main_menu.ID;
          if(this.menuID != null){
            this.fetchingData();
          }
          //
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchingData() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp-api-menus/v2/menus/"+this.menuID)
        .then(response => {
          this.items = response.data.items;
          this.itLoads = true;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
     this.fetchingMenuLocations();
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>