<template>
  <div class="row" data-aos="fade-up" data-aos-duration="1500">
    <h2 class="text-uppercase tx__w-bold mx-auto pb-4" v-html="title"></h2>
    <div class="col-12 d-none d-lg-block">
      <b-tabs
        content-class="mt-3"
        class="approach-tabs"
        justified
        :style="{
          backgroundImage: 'url(' + image + ')',
        }"
      >
        <b-tab v-for="(i, index) in datatabs" :key="'dyn-tab-' + index">
          <template #title>
            <div class="tab-title ">
              <span class="number">0{{index+1}}</span>
              <span class="title">{{i.title}}</span>
            </div>
          </template>
          <div class="w-100 py-5">
            <div class="row justify-content-center py-5">
              <div class="col-12 col-md-8 col-xl-6 text-center">
                  <div class="w-100 px-3 tx__size-md tx__shadow-sm" v-html="i.content"></div>
              </div>
            </div>
          </div>
        </b-tab>

      </b-tabs>
    </div>
    <div class="col-12 d-lg-none">
      <div class="accordion py-4" role="tablist" :style="{backgroundImage: 'url(' + image + ')',}">

          <b-card no-body class="mb-1" v-for="(i, index) in datatabs" :key="index">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="'accordion-'+index">
                <span class="number">0{{index+1}}</span>
                <span class="title">{{i.title}}</span>
              </b-button>
            </b-card-header>
            <b-collapse :id="'accordion-'+index" accordion="'accordion-'+index" role="tabpanel">
              <b-card-body>
                <b-card-text v-html="i.content"></b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    datatabs: Array,
    title: String,
  },
};
</script>

<style>
</style>