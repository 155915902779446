<template>
  <layout-page v-if="itLoads" :classes="pageClasses" v-slot:default="slotProps">
    <head-post :title="page.name" :category="'Category'"></head-post>
    <div class="container-fluid py-5">
      <div class="row justify-content-center py-5">
        <div class="col-12 col-lg-10">
          <div class="row" v-if="postData">
            <post
              v-for="(i, index) in postData"
              :key="index"
              :title="i.title.rendered"
              :img="i.fimg_url.full"
              :excerpt="i.excerpt.rendered"
              :date="i.date"
              :id="i.id"
              :cat="page.name"
              cols="col-md-6 col-lg-4"
              :dummyimg="slotProps.options.dummy_image"
            ></post>
          </div>
        </div>
      </div>
    </div>
  </layout-page>
</template>

<script>
import LayoutPage from "@/layouts/LayoutPage.vue";
import HeadPost from "@/components/template-parts/HeadPost.vue";
import Post from "@/components/insights/Post.vue";

export default {
  components: { LayoutPage, HeadPost, Post },
  data() {
    return {
      id: this.$route.params.id,
      itLoads: false,
      itLoadsData: false,
      page: Object,
      postData: Object,
      pageClasses: ["internal-page", "category-page", this.$route.name],
    };
  },
  methods: {
    fetchingData() {
      this.$axios
        .get(
          this.$axios.defaults.baseURL +
            "wp/v2/posts?categories=" +
            this.$route.params.id
        )
        .then((response) => {
          this.postData = response.data;
          this.itLoadsData = true;
          //this.renderTitle(this.page[0].title.rendered);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchingCat() {
      this.$axios
        .get(
          this.$axios.defaults.baseURL +
            "wp/v2/categories/" +
            this.$route.params.id
        )
        .then((response) => {
          this.page = response.data;
          this.itLoads = true;
          // console.log(this.page);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    renderCatName(categories, theId){
        let found = categories.find(element => element.id === theId);
        return found.name;
      },
  },
  created() {
    this.fetchingCat();
    this.fetchingData();
  },
};
</script>

<style>
</style>