import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex, axios)
// let baseURL = 'http://localhost:8888/hourigan/hourigan-api/wp-json/'
let baseURL = 'http://hourigan.smlus.com/wordpress/wp-json/'

const ModuleReferees = {
  namespaced: true,
  state: {
    referees: []
  },
  mutations: {
    SET_REFEREES(state, referees) {
      state.referees = referees
    }
  },
  getters: {
    allReferees: (state) => state.referees
  },
  actions: {
    getReferees({ commit }) {
      axios.get(baseURL+'wp/v2/referee?per_page=50')
      .then(response => {
      commit('SET_REFEREES', response.data)
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }
}


const ModuleGenOpt = {
  namespaced: true,
  state: {
    options: {}
  },
  mutations: {
    SET_OPTIONS(state, options) {
      state.options = options
    }
  },
  getters: {
    allOptions: (state) => state.options
  },
  actions: {
    getOptions({ commit }) {
      axios.get(baseURL+'acf/v3/options/site-options-page')
      .then(response => {
      commit('SET_OPTIONS', response.data.acf)
      }).catch((error) => {
        console.log(error);
      });
    }
  }
}

const ModuleCategories = {
  namespaced: true,
  state: {
    categories: {}
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    GET_CAT_NAME(state, payload){
      if(state.categories != null){
        let catArray = Object.values(state.categories)
        let found = catArray.find(element => element.id == payload.id);
        return found;
      }
    }
  },
  getters: {
    allCategories: (state) => state.categories
  },
  actions: {
    getCategories({ commit }) {
      axios.get(baseURL+'wp/v2/categories')
      .then(response => {
      commit('SET_CATEGORIES', response.data)

      }).catch((error) => {
        console.log(error);
      });
    },
    getCatById({commit}, payload){
      commit('GET_CAT_NAME', payload);
    }
  }
}

export default new Vuex.Store({
  state: {
    isNavOpen: false
  },
  getters: {
    isNavOpen: state => state.isNavOpen,
  },
  mutations: {
    TOOGLE_NAV(state) {
      state.isNavOpen = !state.isNavOpen
    },
    CLOSE_NAV(state) {
      state.isNavOpen = false;
    }
  },
  actions: {
    toggleNav({ commit }) {
      commit('TOOGLE_NAV')
    },
    closeNav({ commit }) {
      commit('CLOSE_NAV')
    }
    
  },
  modules: {
    referees: ModuleReferees,
    options: ModuleGenOpt,
    categories: ModuleCategories
  }
})
