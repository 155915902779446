<template>
  <div>
    <div class="container-fluid bg__secondary">
      <div class="row justify-content-center h-100">
        <div class="col-12 col-lg-10 title-section">
          <div class="row">
            <div class="col-12 col-lg-6 pb-3">
              <h3 class="tx__c-primary font__primary text-uppercase tx__space-2" v-html="category"></h3>
              <h1 class="text-uppercase tx__w-bold" v-html="title"></h1>
              <time v-if="date" class="text-uppercase">{{ datePostFormat(date) }}</time>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    date: String,
    category: String,
  },
  methods: {
    splitString(stg) {
      let str = stg;
      let splStrt = str.split(" ");
      return splStrt;
    },
    renderTitle(stg) {
      let inputstg = this.splitString(stg);
      inputstg[0] = '<span class="tx__c-primary">' + inputstg[0] + "</span>";
      inputstg = inputstg.join(" ");
      return inputstg;
    },
    datePostFormat(d) {
      let theDate = new Date(d);
      let year = theDate.getFullYear(); // 2019
      const months = {
        0: "jan",
        1: "feb",
        2: "mar",
        3: "apr",
        4: "may",
        5: "jun",
        6: "jul",
        7: "aug",
        8: "sep",
        9: "oct",
        10: "nov",
        11: "dec",
      };
      let monthName = months[theDate.getMonth()];
      let day = theDate.getDate(); // 23

      return monthName + " " + day + ", " + year;
    },
  },
};
</script>

<style>
</style>