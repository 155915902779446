<template>
  <div v-if="itLoadsRelated">
      <h3 class="text-uppercase tx__w-bold mx-auto py-4 text-center text-dark" v-if="relatedPosts.posts.length">
            <span class="tx__c-primary">Related </span>content
          </h3>
          <div class="row">
            <div class="col-12" v-if="itLoadsRelated">
              <div  class="card mb-3" v-for="(i, index) in relatedPosts.posts" :key="index">
                <div class="row no-gutters">
        
                  <div class="col-12">
                    <div class="card-body">
                      <div class="d-flex lign-items-center">
                        <small class="text-muted text-uppercase">{{ datePostFormat(i.post_date) }}</small>
                      </div>
                      <h5 class="card-title" v-html="i.post_title"></h5>
                      <p class="card-text">
                          <router-link :to="{ name: 'post', params: { id: i.ID }}" replace>Read more</router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
  </div>
</template>

<script>
export default {
    props:{
        dummyImage: String,
    },
    data(){
        return{
            relatedPosts: Array,
            itLoadsRelated: false
        }
    },
    watch: {
    // call again the method if the route changes
    $route: "fetchingRelated",
  },
    methods: {
        fetchingRelated() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "related-posts-by-taxonomy/v1/posts/"+this.$route.params.id+"?posts_per_page=3")
        .then((response) => {
          this.relatedPosts = response.data;
          this.itLoadsRelated = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    methodThatForcesUpdate() {
      // ...
      this.$forceUpdate();  // Notice we have to use a $ here
      // ...
    },
    datePostFormat(d) {
      let theDate = new Date(d);
      let year = theDate.getFullYear(); // 2019
      const months = {
        0: "jan",
        1: "feb",
        2: "mar",
        3: "apr",
        4: "may",
        5: "jun",
        6: "jul",
        7: "aug",
        8: "sep",
        9: "oct",
        10: "nov",
        11: "dec",
      };
      let monthName = months[theDate.getMonth()]
      let day = theDate.getDate(); // 23

      return monthName + " "+ day +", " + year;
    }
  },
  created(){
      this.fetchingRelated();
  }
}
</script>

<style>

</style>