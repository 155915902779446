<template>
  <layout-page :classes="pageClasses" v-slot:default="slotProps">
    <section v-if="dataErrored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
    <section v-else>
      <loading v-if="dataLoading"></loading>
    <div class="container-fluid" v-else>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-11">
          <home-cover v-if="itLoads" :imageDesktop="pageData.fimg_url.full" :imageMobile="pageData.acf.group_mobile_photos" />
          <div class="row justify-content-center pt-3 pt-lg-0">
            <div class="col-12 col-lg-8 col-xl-5">
              <span class="line line--red"></span>
              <div
                class="w-100 text-center font__primary tx__size-md tx__w-3 py-4"
                v-html="pageData.content.rendered"
              ></div>
              <span class="line line--red"></span>
              
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center py-5 mt-lg-5 " data-aos="fade-up" data-aos-duration="1500">
        <div class="col-12 col-lg-10 py-5">
          <div class="row align-items-center justify-content-center">
            <div class="col-12 col-lg-6 col-xl-5 order-2 order-lg-1 py-5 py-lg-2">
              <span class="line line--red"></span>
              <div class="w-100 text-center font__primary tx__size-md tx__w-3 py-4" v-html="pageData.acf.intro_content"></div>
              <span class="line line--red"></span>
            </div>
            <div class="col-12 col-lg-6 col-xl-6 offset-xl-1 py-5 order-1 order-lg-2">
              <img
                src="../assets/img/H-home.png"
                alt="Hourigan"
                class="mx-auto img-fluid d-block"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center py-5">
        <div class="col-12 col-lg-10 py-5">
          <home-tabs v-if="itLoads" :title="renderTitle(pageData.acf.title_approach)" :datatabs="pageData.acf.group_approach" :image="pageData.acf.background_approach" />
        </div>
      </div>
      <home-reputation v-if="itLoads" :title="renderTitle(pageData.acf.title_1)" :copy="pageData.acf.copy_1" :link="pageData.acf.link_1" :refdata="getterRefeerees" :dummyimg="slotProps.options.dummy_image" />
    </div>
    <!-- End container -->
    <home-people v-if="itLoads" :image="pageData.acf.people_img" :title="renderTitle(pageData.acf.title_2)" :copy="pageData.acf.copy_2" :link="pageData.acf.link_2" />
    <home-insights v-if="itLoads" :image="pageData.acf.insights_img_1" :imageTwo="pageData.acf.insights_img_2" :title="renderTitle(pageData.acf.title_3 )" :link="pageData.acf.link_3"  />
    <home-community v-if="itLoads" :image="pageData.acf.community_img" :title="renderTitle(pageData.acf.title_4)" :copy="pageData.acf.copy_3" :link="pageData.acf.link_4" />

    </section>
    
  </layout-page>
</template>

<script>
// @ is an alias to /src
import LayoutPage from "@/layouts/LayoutPage.vue";
import HomeCover from "@/components/home/HomeCover.vue";
import HomeTabs from "@/components/home/HomeTabs.vue";
import HomePeople from "@/components/home/HomePeople.vue";
import HomeReputation from "@/components/home/HomeReputation.vue";
import HomeInsights from "@/components/home/HomeInsights.vue";
import HomeCommunity from "@/components/home/HomeCommunity.vue";
import Loading from '@/components/Loading.vue';

export default {
  name: "Home",
  components: {
    LayoutPage,
    HomeCover,
    HomeTabs,
    HomePeople,
    HomeReputation,
    HomeInsights,
    HomeCommunity,
    Loading
  },
  data() {
    return {
      pageClasses: ["home", "bg__secondary"],
      itLoads: false,
      dataLoading: true,
      dataErrored: false,
      pageData: Object,
    };
  },
  head() {
    return {
      title: this.dataLoading ? 'Loading...' : this.pageData._yoast_wpseo_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.dataLoading ? 'Loading...' : this.pageData._yoast_wpseo_metadesc,
        },
      ],
    };
  },
  methods: {
    fetchingData() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp/v2/pages/5")
        .then((response) => {
          this.pageData = response.data;
          this.itLoads = true;
        })
        .catch((error) => {
          console.log(error);
          this.dataErrored = true
        }).finally(() => this.dataLoading = false);
    },
    renderTitle(stg){
        let inputstg = stg.split(" ");
        inputstg[0] = '<span class="tx__c-primary">'+inputstg[0]+'</span>';
        inputstg = inputstg.join(' ');
        return inputstg;
      }
  },
  mounted() {
    this.fetchingData();
    this.$store.dispatch('referees/getReferees');
  },
  computed:{
    getterRefeerees() {
      return this.$store.getters["referees/allReferees"];
    },
  }
};
</script>
<style scoped>
</style>