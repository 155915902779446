<template>
  <footer class="bg__black py-5 site-footer">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-11">
          <div class="row">
            <div class="col-12 col-lg-2 col-xl-3 order-1 order-lg-1">
              <router-link to="/" class="d-block text-center text-lg-left">
                <img :src="data.logo_footer" alt="Hourigan" class="logo">
              </router-link>
            </div>
            <div class="col-12 col-lg-3 col-xl-3  text-center text-lg-left py-3 py-lg-0 order-3 order-lg-2">
              <div class="w-100 pb-3 " v-html="data.address"></div>
              <a :href="'mailto:'+data.email" class="d-block link link--white font-weight-bold" target="_blank">{{data.email}}</a>
              <a :href="'tel:'+data.phone" class="d-block link link--white font-weight-bold">{{data.phone}}</a>
            </div>
            <div class="col-12 col-lg-3 col-xl-3  py-3 py-lg-0 order-2 order-lg-3">
                <nav-footer/>
            </div>
            <div class="col-12 col-lg-4  col-xl-3 order-4 order-lg-4">
              <p class="mb-3 text-center text-lg-left">Newsletter</p>
              <!-- Begin Mailchimp Signup Form -->

<div id="mc_embed_signup" >
<form action="https://hourigan-international.us7.list-manage.com/subscribe/post?u=400562b00ee9bfdc33e3b0e51&amp;id=56636df6bd" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate justify-content-center justify-content-lg-start form-inline" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll" class="justify-content-center justify-content-lg-start d-flex">
      <input type="email" value="" name="EMAIL" class="email form-control" id="mce-EMAIL" placeholder="Enter email" required>
        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px;" aria-hidden="true">
          <input type="text" name="b_400562b00ee9bfdc33e3b0e51_56636df6bd" tabindex="-1" value="">
        </div>
      <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">
    </div>
</form>
</div>

<!--End mc_embed_signup-->
             
                <div class="w-100 social-links py-3 text-center text-lg-left">
                  <a :href="data.linkedin" v-if="data.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
                  <a :href="data.twitter" v-if="data.twitter" target="_blank" class="px-2"><i class="fab fa-twitter-square"></i></a>
                  <a :href="data.facebook" v-if="data.facebook" target="_blank" class="px-2"><i class="fab fa-facebook-square"></i></a>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-10 offset-lg-2 col-xl-9 offset-xl-3 py-3">
              <hr>
              <div class="w-100 d-flex flex-column flex-lg-row justify-content-center">
                <router-link to="/privacy-policy" class="text-center text-lg-left link link--white small">Privacy Policy</router-link>
                <small class="px-3 text-center text-lg-left">© Hourigan International - {{ new Date().getFullYear() }}</small>
                <a href="https://smldesign.com.au/" class="ml-lg-auto py-3 py-lg-0 text-center text-lg-left link link--white small" target="_blank">Design and Build by SML Design</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import NavFooter from "@/components/navigation/NavFooter";
export default {
  props:{
    data: Object
  },
  data() {
    return {
      form: {
        email: ""
      },
    };
  },
  components: {
    NavFooter,
  },
  methods: {
  }
};
</script>

<style>
</style>