<template>
  <ul v-if="itLoads" class="m-0 list-unstyled text-center text-lg-left">
      
      <li v-for="item in items" :key="item.ID" class="pb-2">
        <transition name="fade" mode="out-in">
          <router-link :to="{ path: '/'+item.object_slug }" class="link link--white font-weight-bold">{{ item.title }}</router-link>
          </transition>
    </li>
  </ul>
</template>

<script>
export default {
    data() {
    return {
        locations: Object,
        menuID: String,
        items: Object,
        itLoads: false
    };
  },
  methods: {
    fetchingMenuLocations() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp-api-menus/v2/menu-locations/")
        .then(response => {
          this.locations = response.data;
          this.menuID = this.locations.second_menu.ID;
          //console.log(this.menuID);
          if(this.menuID != null){
            this.fetchingData();
          }
          //
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchingData() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp-api-menus/v2/menus/"+this.menuID)
        .then(response => {
          this.items = response.data.items;
          //console.log(this.items);
          this.itLoads = true;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
     this.fetchingMenuLocations();
     //this.fetchingData();
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>