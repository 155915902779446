<template>
    <layout-page v-if="itLoads" :classes="pageClasses">
        <div class="container-fluid bg__secondary ">
          <div class="row justify-content-center h-100">
            <div class="col-12 col-lg-10 title-section">
            <h1 class="text-uppercase tx__w-bold"><span class="tx__c-primary">{{pageTitle[0]}} </span>{{pageTitle[1]}}</h1>
            </div>
          </div>
        </div>
        <div class="container-fluid py-5 page-content">
          <div class="row position-relative">
            <div class="col-12 col-lg-4 offset-lg-1">
                  <div class="w-100"  v-html="page[0].content.rendered"></div>
            </div>
            <div class="col-12 col-lg-4 featured-image">
              <figure class="figure figure__square">
                <img :src="page[0].fimg_url.full" alt="">
                <span class="stroke stroke-top-left"></span>
            </figure>
            </div>
          </div>
        </div>
        <template v-if="fetchSlug === 'our-reputation'">
        </template>
        <template class="container-fluid" v-else-if="fetchSlug === 'our-people'">
        </template>
        <template class="container-fluid" v-else>
          nothing
        </template>
    </layout-page>
</template>

<script>
import LayoutPage from "@/layouts/LayoutPage.vue";
export default {
  components: {
    LayoutPage
  },
  data() {
    return {
      slug: this.$route.params.slug,
      fetchSlug: String,
      itLoads: false,
      page: [
        {
          title:{
            rendered: 'Page'
          }
        }
      ],
      pageTitle: Array,
      pageClasses: ["internal-page", this.$route.name],
    };
  },
  created() {
    this.fetchingData();
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchingData'
  },
  methods: {
      fetchingData() {
        this.fetchSlug = this.$route.params.slug;
        this.$axios
          .get( this.$axios.defaults.baseURL +'wp/v2/pages/?slug=' +this.fetchSlug )
          .then((response) => {
            this.page = response.data;
            this.renderTitle();
            // console.log(this.pageTitle);
            this.itLoads = true
          })
          .catch((error) => {
            console.log(error)
          })
      },
      splitString(stg){
          let str = stg;
          let splStrt = str.split(" ");
          return splStrt;
      },
      renderTitle(){
        this.pageTitle = this.splitString(this.page[0].title.rendered);
      }
  },
  head() {
    return {
      title: this.page[0].title.rendered,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "hola",
        },
      ],
    };
  },
  
}
</script>

<style>

</style>